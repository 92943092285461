import { constructColumnTitle, mkCellContentCallback } from './hooks'
import { VrAnLoading } from '../../util/components/misc'
import { Button, Col, ListGroup, Row } from 'react-bootstrap'
import { useCallback, useEffect, useRef, useState } from 'react'
import { EntityWithDuplicates } from './state'
import {
    DataEditor,
    GridMouseEventArgs,
    GridSelection
} from '@glideapps/glide-data-grid'
import { drawCell } from '../../table/draw'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectEntityColumnDefs,
    selectEntitiesWithMatches,
    selectIsLoading,
    selectSelectedEntity,
    selectTagDefinitions,
    selectMatchTagDefinitionList,
    selectTagRowDefs
} from './selectors'
import {
    getContributionEntitiesAction,
    getContributionEntityDuplicateCandidatesAction,
    getContributionTagInstances,
    putDuplicateAction
} from './thunks'
import { AppDispatch } from '../../store'
import {
    incrementSelectedEntityIdx,
    openJustificationInput,
    setColumnWidth,
    setSelectedEntityIdx,
    toggleTagDefinitionMenu
} from './slice'
import { selectContribution, selectContributionJustification } from '../selectors'
import { loadTagDefinitionHierarchy } from '../../column_menu/thunks'
import { IBounds, useLayer } from 'react-laag'
import { TagDefinition } from '../../column_menu/state'
import {
    AddTagDefinitionsModal,
    JustificationModal,
    LastMatchModal
} from './components/modals'
import {
    CompleteAssignmentButton,
    ChangeJustificationButton
} from './components/buttons'
import { useAppSelector } from '../../hooks'

export function EntitiesStep() {
    const contributionCandidate = useSelector(selectContribution)
    if (contributionCandidate.value === undefined) {
        return <VrAnLoading />
    }
    return (
        <EntitiesStepBody
            idContributionPersistent={contributionCandidate.value.idPersistent}
        />
    )
}

export type PutDuplicateCallback = ({
    idEntityOriginPersistent,
    idEntityDestinationPersistent,
    justificationTxt,
    keepJustificationForAll,
    onSuccess
}: {
    idEntityOriginPersistent: string
    idEntityDestinationPersistent?: string
    justificationTxt?: string | undefined
    keepJustificationForAll?: boolean | undefined
    onSuccess?: VoidFunction | undefined
}) => Promise<boolean>

export function EntitiesStepBody({
    idContributionPersistent
}: {
    idContributionPersistent: string
}) {
    const dispatch: AppDispatch = useDispatch()
    useEffect(() => {
        dispatch(getContributionEntitiesAction(idContributionPersistent))
            .then(async (entities) => {
                dispatch(loadTagDefinitionHierarchy({}))
                return entities
            })
            .then(async (entities) => {
                await dispatch(
                    getContributionEntityDuplicateCandidatesAction({
                        idContributionPersistent,
                        entityIdPersistentList: entities.map(
                            (entity) => entity.idPersistent
                        )
                    })
                )
            })
    }, [dispatch, idContributionPersistent])
    const contributionJustification = useAppSelector(selectContributionJustification)
    const entities = useSelector(selectEntitiesWithMatches)
    const isLoading = useSelector(selectIsLoading)
    const putDuplicateCallback = async ({
        idEntityOriginPersistent,
        idEntityDestinationPersistent,
        justificationTxt = undefined,
        keepJustificationForAll = undefined,
        onSuccess = undefined
    }: {
        idEntityOriginPersistent: string
        idEntityDestinationPersistent?: string
        justificationTxt?: string | undefined
        keepJustificationForAll?: boolean | undefined
        onSuccess?: VoidFunction | undefined
    }) => {
        const result = await dispatch(
            putDuplicateAction({
                idContributionPersistent,
                idEntityOriginPersistent,
                idEntityDestinationPersistent,
                justificationTxt,
                keepJustificationForAll
            })
        )
        if (result) {
            if (onSuccess !== undefined) {
                onSuccess()
            }
            await new Promise((resolve) => {
                setTimeout(resolve, 500)
            })
            dispatch(incrementSelectedEntityIdx(contributionJustification))
        }
        return result
    }
    if (isLoading) {
        return <VrAnLoading />
    }
    return (
        <>
            <Row className="h-100 overflow-hidden">
                <Col xs={3} className="h-100 overflow-hidden d-flex flex-column">
                    <Row className="h-100 overflow-y-scroll">
                        <EntityConflictList entityConflicts={entities.value} />
                    </Row>
                    <Row className="mt-2 justify-content-center">
                        <CompleteAssignmentButton
                            idContributionPersistent={idContributionPersistent}
                        />
                    </Row>
                </Col>
                <Col className="h-100 overflow-hidden d-flex flex-column">
                    <Row>
                        <Col key="entities-step-hint" className="ms-0">
                            Please check for duplicate entities. Select the first Column
                            to indicate that there is no duplicate.
                        </Col>
                        <Col xs="auto" key="change-justification-button">
                            <ChangeJustificationButton />
                        </Col>
                        <Col xs="auto" key="entities-step-add-tag-button">
                            <Button onClick={() => dispatch(toggleTagDefinitionMenu())}>
                                Show Additional Tag Values
                            </Button>
                        </Col>
                    </Row>
                    <Row className="h-100 w-100 ms-2 mt-3">
                        <div id="portal">
                            <EntityConflictBody
                                putDuplicateCallback={putDuplicateCallback}
                                idContributionPersistent={idContributionPersistent}
                            />
                        </div>
                    </Row>
                </Col>
            </Row>
            <LastMatchModal idContributionPersistent={idContributionPersistent} />
        </>
    )
}

export function EntityConflictBody({
    idContributionPersistent,
    putDuplicateCallback
}: {
    idContributionPersistent: string
    putDuplicateCallback: PutDuplicateCallback
}) {
    const selectedEntity = useSelector(selectSelectedEntity)
    const [tagDefinitionList, tagDefinitionIndices] = useSelector(selectTagDefinitions)
    const matchTags = useSelector(selectMatchTagDefinitionList)
    const dispatch: AppDispatch = useDispatch()
    useEffect(
        () => {
            if (selectedEntity === undefined) {
                return
            }
            const entityMap: { [key: string]: string[] } = {}
            entityMap[selectedEntity.idPersistent] = [
                selectedEntity.idPersistent,
                ...new Set(
                    selectedEntity.similarEntities.value.map(
                        (entity) => entity.idPersistent
                    )
                )
            ]
            dispatch(
                getContributionTagInstances({
                    idContributionPersistent: idContributionPersistent,
                    entitiesGroupMap: entityMap,
                    tagDefinitionList: [...matchTags, ...tagDefinitionList]
                })
            )
        },
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [idContributionPersistent, selectedEntity?.idPersistent, matchTags]
    )
    let body = <span>Please select an entity</span>
    if (selectedEntity !== undefined) {
        if (selectedEntity.similarEntities.isLoading) {
            return <VrAnLoading />
        } else {
            body = (
                <EntitySimilarityItem
                    entity={selectedEntity}
                    putDuplicateCallback={putDuplicateCallback}
                    numMatchTags={matchTags.length}
                    numTags={tagDefinitionList.length}
                />
            )
        }
    }
    return (
        <>
            {body}
            <AddTagDefinitionsModal
                idContributionPersistent={idContributionPersistent}
                tagDefinitionIndices={tagDefinitionIndices}
            />
        </>
    )
}

function NoConflictBody({
    contributionJustification
}: {
    contributionJustification: string | undefined
}) {
    const dispatch = useDispatch()
    return (
        <Col className="h-100 w-100">
            <Row className="justify-content-center">This entity has no conflicts.</Row>
            <Row className="justify-content-center" xs="auto">
                <Button
                    onClick={() =>
                        dispatch(incrementSelectedEntityIdx(contributionJustification))
                    }
                >
                    Next with conflicts
                </Button>
            </Row>
        </Col>
    )
}

const zeroBounds = {
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    bottom: 0,
    right: 0
}

export function EntitySimilarityItem({
    entity,
    putDuplicateCallback,
    numMatchTags,
    numTags
}: {
    entity: EntityWithDuplicates
    putDuplicateCallback: PutDuplicateCallback
    numMatchTags: number
    numTags: number
}) {
    const entityColumnDefs = useSelector(selectEntityColumnDefs)
    const tagRowDefs = useSelector(selectTagRowDefs)
    const contributionJustification = useSelector(selectContributionJustification)
    const matchTagDefinitionList = useSelector(selectMatchTagDefinitionList)
    const { similarEntities, displayTxtDetails: entityDisplayTxtDetails } = entity
    const dispatch = useDispatch()
    const [tooltip, setTooltip] = useState<
        { val: string; bounds: IBounds } | undefined
    >()
    const { layerProps: tooltipLayerProps, renderLayer: tooltipRenderLayer } = useLayer(
        {
            isOpen: tooltip !== undefined,
            triggerOffset: 4,
            auto: true,
            container: 'portal',
            trigger: {
                getBounds: () => tooltip?.bounds ?? zeroBounds
            }
        }
    )

    const timeoutRef = useRef(0)

    const onItemHovered = useCallback(
        (args: GridMouseEventArgs) => {
            const colIdx = args.location[0]
            if (args.kind === 'cell' && colIdx > 0 && args.location[1] == 3) {
                let displayTxtDetails = entityDisplayTxtDetails
                if (colIdx > 1) {
                    displayTxtDetails =
                        similarEntities.value[colIdx - 2].displayTxtDetails
                }
                window.clearTimeout(timeoutRef.current)
                setTooltip(undefined)
                let tooltipValue = ''
                if (typeof entityDisplayTxtDetails == 'string') {
                    tooltipValue = displayTxtDetails as string
                } else {
                    tooltipValue = constructColumnTitle(
                        (displayTxtDetails as TagDefinition).namePath
                    )
                }
                timeoutRef.current = window.setTimeout(() => {
                    setTooltip({
                        val: `Display text source: ${tooltipValue}`,
                        bounds: {
                            // translate to react-laag types
                            left: args.bounds.x,
                            top: args.bounds.y,
                            width: args.bounds.width,
                            height: args.bounds.height,
                            right: args.bounds.x + args.bounds.width,
                            bottom: args.bounds.y + args.bounds.height
                        }
                    })
                }, 1000)
            } else {
                window.clearTimeout(timeoutRef.current)
                timeoutRef.current = 0
                setTooltip(undefined)
            }
        },
        [similarEntities.value, entityDisplayTxtDetails]
    )
    if (similarEntities.errorMsg !== undefined) {
        return <VrAnLoading />
    }
    if (
        similarEntities.value.length == 0 &&
        (entity.justificationTxt !== undefined ||
            contributionJustification !== undefined)
    ) {
        return (
            <>
                <NoConflictBody contributionJustification={contributionJustification} />
                <JustificationModal putDuplicateCallback={putDuplicateCallback} />
            </>
        )
    }
    return (
        <>
            <div
                className="h-100 w-100 mb-2 ms-3 me-3"
                data-testid="table-container-outer"
            >
                <div
                    className="br-12 ps-0 pe-0 h-100 w-100 overflow-hidden"
                    data-testid="table-container-inner"
                >
                    <DataEditor
                        drawCell={drawCell}
                        rows={4 + numTags}
                        getCellContent={mkCellContentCallback(
                            entity,
                            tagRowDefs,
                            numMatchTags,
                            matchTagDefinitionList
                        )}
                        freezeColumns={2}
                        columns={entityColumnDefs}
                        rowSelect="none"
                        height="100%"
                        width="100%"
                        columnSelect="none"
                        rangeSelect="cell"
                        onColumnResize={(_col, size, idx) =>
                            dispatch(setColumnWidth({ idx, width: size }))
                        }
                        onGridSelectionChange={(selection: GridSelection) => {
                            const current = selection.current
                            if (current !== undefined) {
                                //Select range
                                const [colIdx, rowIdx] = current.cell
                                if (rowIdx != 0 || colIdx == 0) {
                                    return
                                }
                                if (colIdx === undefined || colIdx < 2) {
                                    if (
                                        entity.justificationTxt === undefined &&
                                        contributionJustification === undefined
                                    ) {
                                        dispatch(openJustificationInput())
                                    } else {
                                        putDuplicateCallback({
                                            idEntityOriginPersistent:
                                                entity.idPersistent,
                                            idEntityDestinationPersistent: undefined
                                        })
                                    }
                                } else {
                                    putDuplicateCallback({
                                        idEntityOriginPersistent: entity.idPersistent,
                                        idEntityDestinationPersistent:
                                            entity.similarEntities.value[colIdx - 2]
                                                .idPersistent
                                    })
                                }
                            }
                        }}
                        onItemHovered={onItemHovered}
                    />
                    {tooltip != undefined &&
                        tooltipRenderLayer(
                            <div
                                {...tooltipLayerProps}
                                style={{
                                    ...tooltipLayerProps.style,
                                    padding: '8px 12px',
                                    color: 'white',
                                    font: '500 13px Inter',
                                    backgroundColor: 'rgba(0, 0, 0, 0.85)',
                                    borderRadius: 9
                                }}
                            >
                                {tooltip.val}
                            </div>
                        )}
                </div>
            </div>
            <JustificationModal putDuplicateCallback={putDuplicateCallback} />
        </>
    )
}

export function EntityConflictList({
    entityConflicts
}: {
    entityConflicts: EntityWithDuplicates[]
}) {
    const dispatch = useDispatch()
    const selectEntityCallback = (idx: number) => dispatch(setSelectedEntityIdx(idx))
    const selectedEntity = useSelector(selectSelectedEntity)
    if (entityConflicts.length == 0 || entityConflicts[0].similarEntities.isLoading) {
        return <VrAnLoading />
    }
    return (
        <ListGroup>
            {entityConflicts.map((entity, idx) => (
                <EntityConflictListItem
                    entity={entity}
                    active={entity == selectedEntity}
                    key={entity.idPersistent}
                    onClick={() => {
                        selectEntityCallback(idx)
                    }}
                />
            ))}
        </ListGroup>
    )
}

export function EntityConflictListItem({
    entity,
    active,
    onClick
}: {
    entity: EntityWithDuplicates
    active: boolean
    onClick: VoidFunction
}) {
    return (
        <ListGroup.Item active={active} onClick={onClick}>
            {entity.displayTxt}
        </ListGroup.Item>
    )
}
